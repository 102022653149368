import styled from '@emotion/styled'
import { theme } from '../styles'

export const HeaderWrapper = styled.header`
  width: 100%;
  position: fixed;
  z-index: 1000;
  background: #f2f2f2;
  ${'' /* @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  } */}
  .header-container {
    display: flex;
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
    align-items: center;
    justify-content: end;
    padding: 10px 0;
    height: 80px;
    transition: background-color 0.2s ease-out;
    .logo {
      img {
        width: 200px;
        margin-left: -15px;
      }
    }
    > div {
      > div {
        &.menu {
          ul {
            display: flex;
            align-items: center;
            justify-content: center;
            li {
              list-style-type: none;
              a {
                color: #000;
                text-decoration: none;
                font-size: 16px;
                font-weight: 100;
                margin: 0 .8rem;
                outline: none;
                font-weight: bold;
                &:hover {
                  text-decoration: underline;
                }
                &.bordered {
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  padding: 5px 20px;
                }
                ${'' /* @media (max-width: ${props => props.theme.breakpoints.l}) {
                  font-size: 14px;
                }
                @media (max-width: ${props => props.theme.breakpoints.m}) {
                  font-size: 12px;
                } */}
              }
              button {
                color: #000;
                border: 2px solid #8faff4;
                padding: 0 20px;
                cursor: pointer;
                font-weight: 600;
                font-family: ${theme.fontFamily};
                font-size: 16px;
                display: inline-block;
                padding: 5px 10px;
                margin: 0 .8rem;
                border: 1px solid #aaa;
                text-align: center;
                background: #aaa;
                border-radius: 5px;
                outline: none;
                &.no-btn {
                  background: transparent;
                  border: none;
                  padding: 0;
                  letter-spacing: 1px;
                  color: white;
                }
                @media (max-width: ${props => props.theme.breakpoints.l}) {
                  font-size: 14px;
                }
                @media (max-width: ${props => props.theme.breakpoints.m}) {
                  font-size: 12px;
                }
              }
              text-align: center;
              @media (max-width: ${props => props.theme.breakpoints.m}) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`