import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'gatsby'
import { HeaderWrapper } from '../containers/Header'
import HeaderLogo from '../assets/images/6.png'

class Header extends React.Component {

  state = {
    open: false
  }

  componentDidMount() {
    this.manageLayout();
  }

  componentDidUpdate() {
    this.manageLayout()
  }

  manageLayout = () => {
    const parentNode = ReactDOM.findDOMNode(this).parentNode;
    this.wrapper = parentNode.querySelector(`.header-container`);
    // this.burgerMenu = parentNode.querySelector(`.burger-menu`);
    if (parentNode.querySelector('.intro') !== null) {
      window.addEventListener('load', this.handleLoad);
      setTimeout(() => {
        this.bannerHeight = parentNode.querySelector('.intro').clientHeight;
        window.addEventListener('scroll', this.handleScroll, { passive: true });
        if (window.scrollY > 50) {
          this.wrapper.className = `header-container white`;
          // this.burgerMenu.className = `burger-menu white`;
        } else {
          this.wrapper.className = `header-container`;
          // this.burgerMenu.className = `burger-menu`;
        }
      }, 500);
    }
  }

  handleLoad = (e) => {
    if (e.srcElement.scrollingElement.scrollTop > this.bannerHeight) {
      this.wrapper.className = `header-container white`;
      // this.burgerMenu.className = `burger-menu white`;
    }
  }

  handleScroll = (e) => {
    if (e.srcElement.scrollingElement.scrollTop > this.bannerHeight) {
      this.wrapper.className = `header-container white`;
      // this.burgerMenu.className = `burger-menu white`;
    } else {
      this.wrapper.className = `header-container`;
      // this.burgerMenu.className = `burger-menu`;
    }
  }

  handleClickMobileMenu = () => {
    this.setState({
      open: !this.state.open
    });
  }

  render() {

    // const { slug } = this.props;
    
    return (
      <HeaderWrapper className="header">
        <div className="header-container">
          <div className="logo">
            <Link to={`/`}><img src={HeaderLogo} alt="AFC" /></Link>
          </div>
          <div>
            <div className="menu">
              <ul>
                {/* {
                  process.env.NODE_ENV === 'development' ? (
                    <li>
                      <Link to={`/annonces`}>Petites annonces</Link>
                    </li>
                  ) : (
                    <li>
                      <a rel="noreferrer" href="https://api.afcbayonneangletbiarritz.fr/" target="_blank">Petites annonces</a>
                    </li>
                  )
                } */}
                <li>
                  <a 
                    rel="noreferrer" 
                    href={`${process.env.AFC_PROTOCOL}://${process.env.AFC_SUB_DOMAIN}.${process.env.AFC_DOMAIN}/listing`} 
                    target="_blank"
                  >
                    Petites annonces
                  </a>
                </li>
                <li>
                  <a 
                    rel="noreferrer" 
                    href={`${process.env.AFC_PROTOCOL}://${process.env.AFC_SUB_DOMAIN}.${process.env.AFC_DOMAIN}/blog`} 
                    target="_blank"
                  >
                    Blog
                  </a>
                </li>
                {/* <li>
                  <Link to={`/`}>Services</Link>
                </li>
                <li>
                  <Link to={`/`}>Mission</Link>
                </li>
                <li>
                  <Link to={`/`}>L'équipe</Link>
                </li> */}
                {/* <li>
                  <Link to={`/app/mon-compte`} className="bordered">Mon compte</Link>
                </li> */}
                <li>
                  <a 
                    rel="noreferrer" 
                    href={`${process.env.AFC_PROTOCOL}://${process.env.AFC_SUB_DOMAIN}.${process.env.AFC_DOMAIN}/login`} 
                    target="_blank"
                  >
                    Mon compte
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </HeaderWrapper>
    )
  }
}

export default Header;