import styled from '@emotion/styled'

export const MainContainer = styled.div`
  opacity: 0;
  transition: opacity .4s ease-out;
  ${'' /* min-height: calc(100vh - 271px); */}
  &.loaded {
    opacity: 1;
  }
`;
const MainWrapper = styled.main`
  max-width: 100%;
  ${'' /* padding-top: 81px; */}
  ${'' /* min-height: calc(100vh - 190px); */}
  background: #ffffff;
  section {
    background: #ffffff;
    padding-bottom: 5rem;
    position: relative;
    &.odd {
      ${'' /* background: #f2f2f2; */}
      background: #f9f9f9;
      padding-bottom: 3rem;
      margin-bottom: 5rem;
      padding-top: 1px;
      .right {
        position: absolute;
        width: 50%;
        max-height: 100%;
        top: 0;
        right: 0;
        overflow: hidden;
        display: flex;
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          display: none;
        }
        img {
          max-width: 100%;
          object-fit: cover;
        }
      }
    }
    .container {
      max-width: ${props => props.theme.maxWidth};
      margin: 0 auto;
      padding-right: 15px;
      padding-left: 15px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding-right: 30px;
        padding-left: 30px;
      }
      h1 {
        margin: 2.5rem 0 0 0;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        color: #ec5d57;
      }
      &.forms-layout {
        max-width: 840px;
        .row {
          margin-right: 0;
          margin-left: 0;
        }
      }
      .row {
        margin-right: -15px;
        margin-left: -15px;
        &:after {
          clear: both;
          display: table;
          content: " ";
        }

        .col-3, .col-4, .col-5, .col-6, .col-12 {
          float: left;
          position: relative;
          min-height: 1px;
          .hero-item {
            height: 100%;
            max-width: 420px;
            padding-top: 3rem;
            h2 {
              color: #ec5d57;
              font-size: 20px;
              margin: 1rem 0 .5rem;
              font-weight: bold;
            }
            p {
              line-height: 25px;
              margin: 0;
              font-size: 18px;
            }
            span.label {
              background: #f2f2f2;
              display: inline-block;
              padding: 5px 20px;
              border: 1px solid #ccc;
              border-radius: 5px;
            }
            button {
              font-size: 16px;
              margin: 1rem 0 0;
              border-radius: 5px;
              border: 1px solid #6b3c9d;
              background: #ec5d57;
              color: #ffffff;
              box-shadow: rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px;
              padding: 7px 20px;
              cursor: pointer;
              text-transform: none;
              font-weight: bold;
            }
          }
          img {
            max-width: 100%;
            max-height: 360px;
          }
        }
        .col-4 {
          width: 33.33%;
        }
        .col-5 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
        .col-3 {
          width: 25%;
        }
        .donate {
          h2 {
            color: #ec5d57;
            font-size: 20px;
            margin: 1rem 0 1.5rem;
            font-weight: bold;
            max-width: 400px;
          }
          form {
            display: flex;
            align-items: start;
            @media (max-width: ${props => props.theme.breakpoints.m}) {
              display: block;
            }
            > button {
              font-size: 16px;
              margin: 0 0 0 3rem;
              border-radius: 5px;
              border: 1px solid #6b3c9d;
              background: #ec5d57;
              color: #ffffff;
              box-shadow: rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px;
              padding: 7px 40px;
              cursor: pointer;
              text-transform: none;
              font-weight: bold;
            }
            .amounts {
              &:not(:last-of-type) {
                margin-right: 2rem;
              }
              button, input {
                border: 1px solid #7030a0;
                border-radius: 5px;
                padding: 10px 20px;
                background : #fff;
                font-size: 18px;
                outline: none;
                &:focus {
                  border-color: #ec5d57;
                }
                @media (max-width: ${props => props.theme.breakpoints.m}) {
                  width: 100%;
                }
              }
              button {
                cursor: pointer;
              }
              span.caption {
                display: block;
                font-size: 14px;
                padding: 5px 0 0 5px;
              }
            }
          }
        }
        .col-6 {
          width: 50%;
          @media (max-width: ${props => props.theme.breakpoints.m}) {
            width: 100%;
          }
        }
        .col-12 {
          width: 100%;
        }
      }
    }
  }

  form {
    &.register {
      ul {
        list-style: none;
        margin: 1rem 0;
        padding: 0;
        li {
          margin: 1rem 0;
          :before {
            content: '✔';
            margin-right: .5rem;
          }
        }
      }
      h2 {
        color: #ec5d57;
        font-size: 24px;
        margin: 1rem 0 2rem;
        font-weight: bold;
      }
      p {
        line-height: 25px;
        margin: 0;
        font-size: 18px;
      }
      .form-group {
        margin: 15px 0;
        .row {
          margin-right: 0;
          margin-left: 0;
        }
        input {
          padding: 10px 20px;
          width: 100%;
          font-size: 16px;
          &.s {
            width: 320px;
          }
          &.children {
            
          }
        }
        label {
          font-size: 18px;
          display: block;
          padding: 5px 0;
        }
      }
      .buttons-group {
        button {
          color: #8596ae;
          border: 1px solid #8596ae;
          background: #fff;
          display: block;
          &.selected {
            background: #4d73be;
            color: #fff;
          }
          &.featured {
            background: #8596ae;
            color: #fff;
          }
          width: 100%;
          font-size: 16px;
          margin: 5px 0;
          padding: 10px;
          cursor: pointer;
          transition: background-color .1s ease-out;
          &:hover {
            background: #8596ae;
            color: #fff;
          }
        }
      }
      button {
        cursor: pointer;
        &.add-button {
          background: #4d73be;
          width: 50px;
          height: 50px;
          border:0;
          border-radius: 50%;
          margin-right: 1rem;
          text-align: center;
          color: #fff;
          font-size: 18px;
          font-weight: bold;
        }
        &.submit-top {
          font-size: 18px;
          width: 100%;
          padding: 10px;
          border: 1px solid #4d73be;
        }
        &.submit-bottom {
          font-size: 18px;
          width: 100%;
          padding: 10px;
          color: #4d73be;
          border: 1px solid #4d73be;
        }
        &.checkbox {
          font-size: 18px;
          width: 100%;
          padding: 10px;
          border: 1px solid #4d73be;
          transition: background-color .1s ease-out;
          &:hover {
            background: #4d73be;
            color: #fff;
          }
        }
        &.submit {
          width: 100%;
          background: #232a34;
          color: #fff;
          font-weight: bold;
          font-size: 18px;
          margin: 5rem 0;
          padding: 10px;
          transition: background-color .1s ease-out;
          &:hover {
            background: #4d73be;
          }
        }
      }

      .col-3, .col-4, .col-6, .col-12 {
        padding-left: 15px;
        padding-right: 15px;
        .col-3, .col-4, .col-6, .col-12 {
          padding-left: 0;
          padding-right: 0;
          &:first-of-type {
            padding-right: 15px;
          }
          &:last-of-type {
            padding-left: 15px;
          }
        }
      }

      .col-6.regular {
        background: #f7f7f7;
        min-height: 400px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: inherit;
        justify-content: space-between;
      }
      .col-6.featured {
        min-height: 400px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: inherit;
        justify-content: space-between;
        button {
          font-size: 18px;
          width: 100%;
          padding: 10px;
          border: 2px solid #4d73be;
          
          &.submit-bottom {
            background: #232a34;
            color: #fff;
          }
        }
      }
    }
  }
`

export const View = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;

  a {
    color: ${props => props.theme.colors.links};
    text-decoration: none;
    transition: color .1s ease-out;
    font-weight: 600;
    &:hover {
      color: #333;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  &:before {
    content: " ";
    display: flex;
  }

  .col-md-12, 
  .col-xl-8, 
  .col-lg-9, 
  .col-xl-4, 
  .col-lg-3, 
  .col-xl-5, 
  .col-md-6 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    &.centered {
      margin: 0 auto;
    }
    &.text-right {
      text-align: right;
    }
  }
  .col-xl-8 {
    @media (min-width: 1200px) {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
  }
  .col-lg-9 {
    @media (min-width: 1200px) {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
  .col-lg-3 {
    @media (min-width: 1200px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .col-xl-4 {
    @media (min-width: 1200px) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
  .col-xl-5 {
    @media (min-width: 1200px) {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
  }
  .col-md-6 {
    @media (min-width: 1200px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
`;


export const Baseline = styled.div`
  background-color: #f8f8f8;
  position: relative;
  padding: 40px 0;

  h1, h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 1px 0 3px;
  }

  nav#breadcrumb {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #555;
    ul {
      margin: 0;
      padding: 0;
      line-height: 23px;
      li {
        display: inline-block;
        list-style: none;
        margin: 0 0 0 7px;
        &:not(:first-of-type):before {
          content: "";
          display: inline-block;
          height: 0;
          width: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-left: 4px solid #555;
          font-size: 14px;
          margin-right: 12px;
        }
        a {
          text-decoration: none;
          transition: all .2s ease-in-out;
          color: #000;
          font-weight: 500;
          &:hover {
            color: #0080ff;
          }
        }
      }
    }
  }
`;

export const Form = styled.div`
  margin-top: 40px;
  div.summary {
    display: block;
    text-align: center;
    color: gray;
    margin-bottom: 33px;
    h3 {
      display: block;
      font-weight: 700;
      color: #333;
      font-size: 26px;
      margin: 0;
      padding: 0
    }
    span {
      display: block;
      margin-top: 4px;
    }
  }

  form {
    .input-text {
      position: relative;
      span {
        position: absolute;
        top: 0;
        text-align: center;
        line-height: 50px;
        width: 48px;
        height: 48px;
        font-size: 18px;
        background-color: #f8f8f8;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        display: block;
        border-radius: 4px 0 0 4px;
        svg {
          fill: #a0a0a0;
          width: 16px;
        }
      }
      input {
        border: 1px solid #e0e0e0;
        box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
        padding-left: 65px;
        height: 48px;
        line-height: 50px;
        outline: 0;
        font-size: 16px;
        color: gray;
        margin: 0 0 16px;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        display: block;
        background-color: #fff;
        font-weight: 500;
        opacity: 1;
        border-radius: 4px;
        transition: all .1s ease-in-out;
      }
    }
    button.submit-form {
      width: 100%;
      text-align: center;
      display: block;
      top: 0;
      padding: 10px 20px;
      line-height: 24px;
      color: #fff;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      transition: all .2s ease-in-out;
      cursor: pointer;
      overflow: hidden;
      border: none;
      border-radius: 4px;
      margin-top: 10px;
      z-index: 1;
      font-family: ${props => props.theme.fontFamily};
      box-shadow: ${props => props.theme.colors.buttonsShadow};
      background: ${props => props.theme.colors.buttonsBackground};
      &:hover {
        background: ${props => props.theme.colors.buttonsBackgroundHover};
        box-shadow: none;
        &.disabled {
          background: ${props => props.theme.colors.buttonsDisabled};
          box-shadow: none;
          cursor: default;
        }
      }
      &.disabled {
        background: ${props => props.theme.colors.buttonsDisabled};
        box-shadow: none;
        cursor: default;
      }
    }
  }
`;
export default MainWrapper
